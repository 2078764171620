import React, {useContext, useEffect, useState} from "react";
import '../../App.css';
import {API} from "aws-amplify";
import {
    GroupItem,
    Item,
    RequiredRule,
    SimpleItem,
    ButtonItem,
    EmptyItem,
    StringLengthRule,
    PatternRule, AsyncRule, Label
} from "devextreme-react/form";
import {Form} from "devextreme-react";
import {createLoRaWANGateway} from "../../graphql/mutations";
import notify from "devextreme/ui/notify";
import {idgenerator} from "../../settings/functions";
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import ErrorPage from "../Pages/ErrorPage";
import CustomStore from "devextreme/data/custom_store";
import {customersStore} from "../../common/customersStore";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Map from "../Map";
import {listObjectsMinimal} from "../../graphql/customQueries";

const euiPattern = '^[a-fA-F0-9]+$';

const euiValidation = async (params) => {
    let eui = params.value?.toLowerCase();
    let not_exists= false;
    try {
        const {data : {LoRaWANGatewayByEUI: {items: page}}} = await API.graphql({
            query: "query LoRaWANGatewayByEUI {LoRaWANGatewayByEUI(eui: \""+eui+"\", limit: 1) {items {id}}}",
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        if (page.length===0){
            not_exists = true;
        }
    } catch (err) { console.error(err) }
    return not_exists;
}

function GatewayCreateForm() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [objects, setObjects] = useState([]);
    const [formInputs, setFormInputs] = useState({
            customer: (authContext?.accesslevel===1) ? null : authContext?.userInfoFromDB?.customer,
            name: '',
            website: '',
            backhaulSimCard: '',
            foto: '',
            eui: '',
            appkey: '',
            appeui: '',
            comments: '',
            latitude: '',
            longitude: '',
            altitude: '',
            country: '',
            tag: '',
            objectID: null
    });

    const renderStreetMap = () => {
        return (
            <Map
                longitude={formInputs.longitude}
                latitude={formInputs.latitude}
                title={formInputs.name}
            />
        )
    }

    // Fetches all Room and Zone Objects
    const fetchObjects = async () => {
        try {
            let filter =  {or: [ {type: {eq: 'ROOM'}}, {type: {eq: 'ZONE'}}]}
            if (formInputs.customer) {
                filter = {and: [{or: [ {type: {eq: 'ROOM'}}, {type: {eq: 'ZONE'}}]}, {customerID: {eq: formInputs.customer?.id}}]}
            }
            let res = [];
            let nt = null;
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data: {listObjects: {items: page, nextToken}}} = await API.graphql({
                    query: listObjectsMinimal,
                    variables: {nextToken: nt, filter: filter},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                nt = nextToken;
                res = res.concat(page);
            } while (nt !== null)
            setObjects(res);
        } catch (err) { console.error(err) }
    }

    // handles the onboard of the Gateway
    const handleGatewayCreate = async (e) => {
        e.preventDefault();
        if (authContext?.authState !== AuthState.SignedIn) {alert("Please sign in first");e.cancel = true;return;}
        if (!formInputs.customer) {alert("Please select a customer");e.cancel = true;return;}

        let customer_formatted = formInputs.customer.bmonc_customer_name.replace(/ /g, "-");
        let groupWrite = [];
        let groupRead = [];
        if (authContext?.groups.includes("BMONCMaster")) { // use the selected groups
            groupWrite.push(customer_formatted+"-ADMIN");
            groupRead.push(customer_formatted+"-ADMIN");
            groupRead.push(customer_formatted+"-USER")
        } else {
            console.error("Error: Your current permissions do not allow this action");
        }
        let items = {
            id: idgenerator(),
            name: formInputs.name,
            website: formInputs.website,
            backhaulSimCard: formInputs.backhaulSimCard,
            eui: formInputs.eui.toLowerCase(),
            comments: formInputs.comments,
            latitude: formInputs.latitude,
            longitude: formInputs.longitude,
            altitude: formInputs.altitude,
            country: formInputs.country,
            foto: undefined, //(formInputs.uploadedfiles & formInputs.uploadedfiles !== [] ?  {key: formInputs.uploadedfiles[0].key}: undefined),
            //tag: '', // example: owner = Kunde
            objectID: formInputs.objectID,
            customerID: formInputs.customer ? formInputs.customer.id : undefined,
            createdAt: (new Date()).toISOString(),
            updatedAt: (new Date()).toISOString(),
            groupRead: groupRead,
            groupWrite: groupWrite
        };
        return API.graphql({
            query: createLoRaWANGateway,
            variables: {
                input: items
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(() => {
            notify("Das Gateway wurde angelegt.", "success", 3000);
            navigate(-1);
        }).catch((e) => {
            alert(e);
            e.cancel = true;
        });
    }

    const handleCustomerChange = (e) => {
        let sel_customer = e.value;

        // this prevents the selection of a not yet physically created customer in thingsboard
        if (!sel_customer || !sel_customer['tb_customerID']) {
            notify("Der Kunde hat noch keine Benutzer oder ist noch im DEMO Modus.", "warning", 3000);
            e.cancel = true;
            return;
        }

        setFormInputs({ ...formInputs,
            customer: sel_customer,
            objectID: null
        });
    }

    // Use the submitted data to set the state
    const handleChange = (event, name) => {
        if (!event.event) return
        if (!name) { // SelectBox or DateBox
            const {name, value} = event.event.target
            setFormInputs({ ...formInputs,
                [name]: value
            });
        } else { // TextBox or similar
            setFormInputs({ ...formInputs,
                [name]: event.value
            });
        }
    }

    // handles object change and some geo location autocomplete
    const handleObjectChange = (e) => {
        const object = e.value;
        setFormInputs({ ...formInputs,
            objectID: object.id,
            longitude: (object?.longitude ? object.longitude: null),
            latitude: (object?.latitude ? object.latitude: null),
            altitude: (object?.altitude ? object.altitude: null)
        });
    }

    useEffect(() => {
        fetchObjects();
    }, []);


    if (authContext?.accesslevel === 1) {
        return (
            <div className={"GatewayCreateForm"}>
                <Header />
                <div className={"Content"}>
                    <h2 className={"headline"}>{t("gatewayslist.headline")}</h2>
                    <form action="create-gateway-action" onSubmit={handleGatewayCreate}>
                        <Form colCount={2}>
                            { (authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                                <GroupItem caption={t("global.customer")} colSpan={2}>
                                    <SimpleItem dataField={"customer"}
                                                editorType={'dxSelectBox'}
                                                editorOptions={{
                                                    dataSource: new CustomStore({...customersStore,
                                                        onLoading(loadOptions) {
                                                            loadOptions.sorting = "name"
                                                        }
                                                    }),
                                                    onValueChanged: handleCustomerChange,
                                                    displayExpr: authContext?.accesslevel === 1 ? "bmonc_customer_name" : "name",
                                                    searchEnabled: true,
                                                }}
                                    >
                                        <RequiredRule/>
                                        <Label text={t("global.customer")}/>
                                    </SimpleItem>
                                </GroupItem>
                                : null }
                            <GroupItem caption={t("global.information")} colSpan={1}>
                                <Item dataField="name" colSpan={1}
                                      editorOptions={{value: formInputs.name, onValueChanged: handleChange}}>
                                    <RequiredRule/>
                                    <Label text={t("global.name")}/>
                                </Item>
                                <Item dataField="eui" colSpan={1}
                                      editorOptions={{value: formInputs.eui, onValueChanged: handleChange}}>
                                    <RequiredRule/>
                                    <StringLengthRule message="EUI should be 16 HEX" min={16} max={16}/>
                                    <PatternRule message="Only use lowercase alphanumerical chars"
                                                 pattern={euiPattern}/>
                                    <AsyncRule message="A gateway with the same EUI is already registered"
                                               validationCallback={euiValidation}/>
                                    <Label text={t("global.eui")}/>
                                </Item>
                                <SimpleItem dataField={"comments"} editorType="dxTextArea" editorOptions={{
                                    height: "150",
                                    value: formInputs.comments,
                                    onValueChanged: handleChange
                                }}>
                                    <Label text={t("global.comment")}/>
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem caption={t("global.location")} colSpan={1}>
                                <SimpleItem dataField={"object"}
                                            editorType={'dxSelectBox'}
                                            editorOptions={{
                                                dataSource: {
                                                    store: objects,
                                                    filter: formInputs.customer ? ["customerID", "=", formInputs.customer.id] : null,
                                                    sort: "name"
                                                },
                                                onValueChanged: handleObjectChange,
                                                displayExpr: "name",
                                                searchEnabled: true,
                                            }}
                                >
                                    <Label text={t("global.relation")}/>
                                </SimpleItem>
                                <SimpleItem dataField={"streetmap"}
                                            render={renderStreetMap}
                                >
                                    <Label text={t("global.map")}/>
                                </SimpleItem>
                                <Item dataField={"country"} colSpan={1} editorType="dxSelectBox" editorOptions={{
                                    dataSource: t('countries:countries',{ returnObjects: true }),
                                    value: formInputs.country,
                                    displayExpr:"text",
                                    valueExpr:"val",
                                    searchEnabled: true,
                                    onValueChanged: (e) => handleChange(e, 'country')
                                }}>
                                    <Label text={t("global.country")}/>
                                </Item>
                            </GroupItem>
                            <GroupItem caption={t("global.details")} colSpan={1}>
                                <Item dataField="website" colSpan={1}
                                      editorOptions={{value: formInputs.website, onValueChanged: handleChange}}>
                                      <Label text={t("global.website")}/>
                                </Item>
                                <Item dataField="backhaulSimCard" colSpan={1}
                                      editorOptions={{value: formInputs.backhaulSimCard, onValueChanged: handleChange}}>
                                    <Label text={t("global.backhaulsimcard")}/>
                                </Item>
                            </GroupItem>}
                            <EmptyItem/>
                            <EmptyItem/>
                            <GroupItem cssClass={"ButtonGroupItem"} colCount={2} direction="column">
                                <ButtonItem horizontalAlignment="right"
                                            colSpan={1}
                                            buttonOptions={{
                                                text: t("gatewayscreate.button1"),
                                                type: 'success',
                                                useSubmitBehavior: true
                                            }}/>
                                <ButtonItem horizontalAlignment="right"
                                            colSpan={1}
                                            buttonOptions={{
                                                text: t("global.cancel"),
                                                type: 'cancel',
                                                onClick: () => {navigate(-1);}
                                            }}/>
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            </div>
        );
    } else {
        return <ErrorPage/>;
    }
}

export default GatewayCreateForm;