import React, {useEffect, useState} from "react";
import {AmplifyAuthenticator, AmplifySignIn, AmplifySignUp} from '@aws-amplify/ui-react';
import {API, Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Landingpage from "./components/Pages/Landingpage";
import Buildings from "./components/Objects/ObjectsList";
import GatewaysList from "./components/Gateways/GatewaysList";
import Customers from "./components/Pages/Customers";
import Sensors from "./components/Pages/Sensors";
import LoRaGateways from "./components/Pages/LoRaGateways";
import Objects from "./components/Pages/Objects";
import Statuses from "./components/Pages/Statuses";
import Reports from "./components/Pages/Reports";
import ObjectsList from "./components/Objects/ObjectsList";
import ObjectCreateForm from "./components/Objects/ObjectCreateForm";
import DeviceRegisterForm from "./components/Sensors/DeviceRegisterForm";
import DeviceTypeRegisterForm from "./components/Sensors/DeviceTypeRegisterForm";
import CustomersList from "./components/Customers/CustomersList";
import BecomeCustomer from "./components/Customers/BecomeCustomer";
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {AuthProvider} from "./contexts/AuthContext";
import News from "./components/Pages/News";
import Support from "./components/Pages/Support";
import GatewayCreateForm from "./components/Gateways/GatewayCreateForm";
import DeviceTypesList from "./components/Sensors/DeviceTypesList";
import DevicesList from "./components/Sensors/DevicesList";
import StatisticsList from "./components/Reports/StatisticsList";
import { useTranslation } from 'react-i18next';
import Status from "./components/Status/Status";
import UsersTreeList from "./components/Customers/UsersTreeList";
import './App.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.css';
import deMessages from "devextreme/localization/messages/de.json";
//import enMessages from "devextreme/localization/messages/en.json";
import { locale, loadMessages } from "devextreme/localization";
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";
import {getAccessLevel} from "./settings/functions";
import Sensor3gSwap from "./components/Sensors/Sensor3gSwap";
import {getUserWithCustomer} from "./graphql/customQueries";
import ErrorPage from "./components/Pages/ErrorPage";

// Translations for the Amplify Authenticator v1
I18n.putVocabulariesForLanguage("de", {
    [Translations.SIGN_IN_HEADER_TEXT]: "BMONC Anmeldung",
    [Translations.FORGOT_PASSWORD_TEXT]: "Passwort vergessen?",
    [Translations.RESET_PASSWORD_TEXT]: "Passwort zurücksetzen",
    [Translations.NO_ACCOUNT_TEXT]: "Noch keinen Account?",
    [Translations.SIGN_IN_ACTION]: "Anmelden",

    [Translations.CODE_LABEL]: "Bestätigungscode",
    [Translations.CODE_PLACEHOLDER]: "Code eingeben",
    [Translations.NEW_PASSWORD_LABEL]: "Neues Passwort",
    [Translations.NEW_PASSWORD_PLACEHOLDER]: "Neues Passwort eingeben",
    [Translations.RESET_YOUR_PASSWORD]: "Passwort zurücksetzen",
    [Translations.BACK_TO_SIGN_IN]: "Zurück zur Anmeldung",
    [Translations.SEND_CODE]: "Code senden",
    [Translations.SUBMIT]: "Senden",

    [Translations.CREATE_ACCOUNT_TEXT]: "Account erstellen",
    [Translations.SIGN_UP_HEADER_TEXT]: "Neuen Account erstellen",
    [Translations.EMAIL_LABEL]: "E-Mail Adresse",
    [Translations.EMAIL_PLACEHOLDER]: "E-Mail Adresse engeben",
    [Translations.PASSWORD_PLACEHOLDER]: "Ihr Passwort",
    [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "Sie haben bereits einen Account?",
    [Translations.SIGN_IN_TEXT]: "Anmelden",
    [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: "Account erstellen",
});

Amplify.configure(awsconfig);

function App() {
    const { t } = useTranslation();
    const [auth, setAuth] = useState({auth: {}, user:{}, userInfoFromDB:{}});

    useEffect(() => {
        loadMessages(deMessages);
        //loadMessages(deMessages);
        locale(navigator.language);
        return onAuthUIStateChange(async (nextAuthState, authData) => {
            if (authData !== undefined) {
                try {
                    let {data : {getUser: u1}} = await API.graphql({
                        query: getUserWithCustomer,
                        variables: {id: authData.username},
                        authMode: 'AMAZON_COGNITO_USER_POOLS'
                    });
                    if (u1 === null) {
                        let {data : {getUser: u}} = await API.graphql({
                            query: getUserWithCustomer,
                            variables: {id: authData.attributes['email']},
                            authMode: 'AMAZON_COGNITO_USER_POOLS'
                        });
                        u1=u;
                    }
                    let groups = authData.signInUserSession.accessToken.payload["cognito:groups"];
                    let customerID = u1.customerID ? u1.customerID : "";
                    setAuth({
                        authState:nextAuthState,
                        user:authData,
                        username: authData.attributes.email,
                        customerID:customerID,
                        groups: groups,
                        accesslevel: getAccessLevel(groups),
                        userInfoFromDB:u1
                    });
                } catch (err) {
                    console.error(err)
                }
            } else {
                setAuth({
                    authState:nextAuthState,
                    user: null,
                    username: null,
                    customerID: null,
                    groups: [],
                    accesslevel: getAccessLevel([]),
                    userInfoFromDB: null
                });
            }
        });
    }, []);

    return auth.authState === AuthState.SignedIn ? (
            <AuthProvider value={auth}>
                <div className="App">
                    <Router>
                        <Routes>
                            <Route path="/" element={<Landingpage/>} />
                            <Route path="/customers" element={<Customers/>} />
                            <Route path="/customers-list" element={<CustomersList/>} />
                            <Route path="/users-list" element={<UsersTreeList/>} />
                            <Route path="/become-customer" element={<BecomeCustomer/>} />
                            <Route path="/sensors" element={<Sensors/>} />
                            <Route path="/sensors/3g-sensors-swap/:id" element={<Sensor3gSwap/>} />
                            <Route path="/loragateways" element={<LoRaGateways/>} />
                            <Route path="/objects" element={<Objects/>} />
                            <Route path="/object-create" element={<ObjectCreateForm/>} />
                            <Route path="/object-list" element={<ObjectsList/>} />
                            <Route path="/statuses" element={<Statuses/>} />
                            <Route path="/status" element={<Status/>} />
                            <Route path="/reports" element={<Reports/>} />
                            <Route path="/support" element={<Support/>} />
                            <Route path="/news" element={<News/>} />
                            <Route path="/buildings" element={<Buildings />} />
                            <Route path="/gateways-list" element={<GatewaysList/>} />
                            <Route path="/gateway-create" element={<GatewayCreateForm/>} />
                            <Route path="/sensors-manage" element={<DevicesList/>} />
                            <Route path="/sensors-register" element={<DeviceRegisterForm/>} />
                            <Route path="/sensors-erstanlage" element={<DeviceTypeRegisterForm/>} />
                            <Route path="/sensortypes-manage" element={<DeviceTypesList/>} />
                            <Route path="/statistics" element={<StatisticsList/>} />
                            <Route path="*" element={<ErrorPage />} />
                        </Routes>
                    </Router>
                </div>
            </AuthProvider>
        ): (
            <div className={"Loginpage"}>
            <div className={"App-logo"}>
                <img src={process.env.PUBLIC_URL+'/images/bmonc-logo.svg'} alt={"logo"}/>
            </div>
            <AmplifyAuthenticator usernameAlias="email">
                <AmplifySignIn
                  slot="sign-in"
                  usernameAlias="email"
                  formFields={[
                      {
                          type: "email",
                          label: t('login.label1')+ " *",
                          inputProps: { required: true, autocomplete: "username" },
                      },
                      {
                          type: "password",
                          label: t('login.label2')+ " *",
                          inputProps: { required: true, autocomplete: "password" },
                      },
                  ]}
                />
                <AmplifySignUp
                  slot="sign-up"
                  usernameAlias="email"
                  formFields={[
                      {
                          type: "custom:firstname",
                          label: t('login.label3')+ " *",
                          inputProps: { required: true, autocomplete: "firstname" },
                      },
                      {
                          type: "custom:lastname",
                          label: t('login.label4')+ " *",
                          inputProps: { required: true, autocomplete: "lastname" },
                      },
                      {
                          type: "email",
                          label: t('login.label5')+ " *",
                          inputProps: { required: true, autocomplete: "email" },
                      },
                      {
                          type: "password",
                          label: t('login.label6')+ " *",
                          inputProps: { required: true, autocomplete: "password" },
                      },
                  ]}
                />
            </AmplifyAuthenticator>
            </div>
    );
}

export default App;
