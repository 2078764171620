import React, {useContext, useState} from "react";
import {API} from "aws-amplify";
import {
    GroupItem,
    Item,
    RequiredRule,
    SimpleItem,
    ButtonItem,
    StringLengthRule,
    PatternRule, Label, EmptyItem
} from "devextreme-react/form";
import {Form} from "devextreme-react";
import {createDeviceType} from "../../graphql/mutations";
import notify from "devextreme/ui/notify";
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import {useTranslation} from "react-i18next";
import {Switch} from "devextreme-react/switch";
import CustomStore from "devextreme/data/custom_store";
import {wirelessDeviceProfilesStore} from "../../common/deviceProfilesStore";
import {destinationsStore} from "../../common/destinationsStore";
import {wirelessServiceProfilesStore} from "../../common/wirelessServiceProfilesStore";
import ErrorPage from "../Pages/ErrorPage";
import {useNavigate} from "react-router-dom";

const appeuiPattern = '^[a-zA-Z0-9]+$';
const appkeyPattern = '^[a-zA-Z0-9]+$';

function DeviceTypeRegisterForm() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [formInputs, setFormInputs] = useState({
        name: '',
        producer: '',
        website: '',
        //deveui: '',
        appeui: '',
        appkey: '',
        description: '',
        // Wireless connectivity Device Profile
        wlDeviceProfile: '',
        // Wireless connectivity Service Profile
        wlServiceProfile: '',
        // Wireless connectivity Destination
        destination: '',
        tag: '', //not used yet,
        is3gDeviceType: false
    });

    // handles the "Erstanlage" of a Device aka. DeviceType
    const handleDeviceTypeRegister = (e) => {
        e.preventDefault();
        return API.graphql({
            query: createDeviceType,
            variables: {
                input: {
                    name: formInputs.name,
                    producer: formInputs.producer,
                    website: (formInputs.website !== '' ?  formInputs.website: undefined),
                    //deveui: formInputs.deveui,
                    appeui: formInputs.is3gDeviceType ? '' : formInputs.appeui,
                    appkey: formInputs.is3gDeviceType ? '' : formInputs.appkey,
                    description: formInputs.description,
                    //tag: '', // example: owner = Kunde
                    wlDeviceProfileID: formInputs.is3gDeviceType ? '' : formInputs.wlDeviceProfile,
                    wlServiceProfileID: formInputs.is3gDeviceType ? '' : formInputs.wlServiceProfile,
                    destinationName: formInputs.destination,
                    creator: authContext?.user?.username,
                    status: 'ACTIVE',
                    is3gDeviceType: formInputs.is3gDeviceType
                },
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(() => {
            notify("Der Sensor Typ wurde angelegt.", "success", 3000);
            navigate("/sensors");
        }).catch((e) => {
            alert(JSON.stringify(e));
            e.cancel = true;
        });
    }

    // Use the submitted data to set the state
    const handleChange = (event, name) => {
        if (!name) { // SelectBox or DateBox
            const {name, value} = event.event.target
            setFormInputs({
                ...formInputs,
                [name]: value
            });
        } else { // TextBox or similar
            setFormInputs({
                ...formInputs,
                [name]: event.value
            });
        }
    }


    if (authContext?.authState === AuthState.SignedIn) {
        return (
            <div className={"DeviceTypeRegisterForm"}>
                <Header />
                <div className={"Content"}>
                    <h2 className={"headline"}>{t("devicetypescreate.headline")}</h2>
                    <form action="register-devicetype-action" onSubmit={handleDeviceTypeRegister}>
                        <Form colCount={2}>
                            <GroupItem caption="Information" colSpan={1}>
                                <SimpleItem dataField={"object"}>
                                    <Switch onValueChanged={(e) => setFormInputs({...formInputs, is3gDeviceType: e.value})}
                                            value={formInputs.is3gDeviceType}
                                            switchedOffText="OFF"
                                            switchedOnText="ON"/>
                                    <Label text={t("global.3gSensorType")}/>
                                </SimpleItem>
                                <Item dataField="name" colSpan={1}
                                      editorOptions={{value: formInputs.name, onValueChanged: handleChange}}>
                                    <RequiredRule message={"Name is required"}/>
                                    <Label text={"Name"}/>
                                </Item>
                                <Item dataField="producer" colSpan={1} editorOptions={{
                                    value: formInputs.producer,
                                    onValueChanged: handleChange
                                }}>
                                    <RequiredRule/>
                                    <Label text={t("global.producer")}/>
                                </Item>
                                <Item dataField="website" colSpan={1} editorOptions={{
                                    value: formInputs.website,
                                    onValueChanged: handleChange
                                }}>
                                    <Label text={t("global.website")}/>
                                </Item>
                                <SimpleItem dataField={"destination"}
                                            editorType={'dxSelectBox'}
                                            editorOptions={{
                                                dataSource: new CustomStore({...destinationsStore}),
                                                onValueChanged: (e) => handleChange(e, 'destination'),
                                                valueExpr: 'name',
                                                displayExpr: 'name',
                                                searchEnabled: true,
                                            }}
                                >
                                    <RequiredRule/>
                                    <Label text={t("global.destination")}/>
                                </SimpleItem>
                                {formInputs.is3gDeviceType ? null :
                                <SimpleItem dataField={"wlDeviceProfile"}
                                            editorType={'dxSelectBox'}
                                            editorOptions={{
                                                dataSource: new CustomStore({...wirelessDeviceProfilesStore}),
                                                onValueChanged: (e) => handleChange(e, 'wlDeviceProfile'),
                                                valueExpr: 'id',
                                                displayExpr: 'name',
                                                searchEnabled: true,
                                            }}
                                >
                                    <RequiredRule/>
                                    <Label text={t("global.wlDeviceProfile")}/>
                                </SimpleItem>}
                                {formInputs.is3gDeviceType ? null :
                                <SimpleItem dataField={"wlServiceProfile"}
                                            editorType={'dxSelectBox'}
                                            editorOptions={{
                                                dataSource: new CustomStore({...wirelessServiceProfilesStore}),
                                                onValueChanged: (e) => handleChange(e, 'wlServiceProfile'),
                                                valueExpr: 'id',
                                                displayExpr: 'name',
                                                searchEnabled: true,
                                            }}
                                >
                                    <RequiredRule/>
                                    <Label text={t("global.wlServiceProfile")}/>
                                </SimpleItem>}
                            </GroupItem>
                            <GroupItem caption="Details" colSpan={2}>
                                {/*<Item dataField="deveui" colSpan={1}
                                      editorOptions={{value: formInputs.deveui, onValueChanged: handleChange}}>
                                    <StringLengthRule message="Device EUI is max. 16 HEX" max={16}/>
                                    <PatternRule message="Only use lowercase alphanumerical chars"
                                                 pattern={deveuiPattern}/>
                                </Item>*/}
                                {formInputs.is3gDeviceType ? null :
                                <Item dataField="appeui" colSpan={1}
                                      editorOptions={{value: formInputs.appeui, onValueChanged: handleChange}}>
                                    <StringLengthRule max={16} message="App EUI is max. 16 HEX"/>
                                    <PatternRule message="Only use alphanumerical chars"
                                                 pattern={appeuiPattern}/>
                                    <Label text={t("global.appeui")}/>
                                </Item>}
                                {formInputs.is3gDeviceType ? null :
                                <Item dataField="appkey" colSpan={1}
                                      editorOptions={{value: formInputs.appkey, onValueChanged: handleChange}}>
                                    <StringLengthRule max={32} message="App Key is max. 32 HEX"/>
                                    <PatternRule message="Only use alphanumerical chars"
                                                 pattern={appkeyPattern}/>
                                    <Label text={t("global.appkey")}/>
                                </Item>}
                                <SimpleItem dataField={"description"} editorType="dxTextArea" editorOptions={{
                                    height: "150",
                                    value: formInputs.description,
                                    onValueChanged: handleChange
                                }}><Label text={t("global.description")}/></SimpleItem>
                            </GroupItem>
                            <EmptyItem/>
                            <GroupItem cssClass={"ButtonGroupItem"} colCount={2}>
                                <ButtonItem horizontalAlignment="right"
                                            buttonOptions={{
                                                text: t("devicetypescreate.button1"),
                                                type: 'success',
                                                useSubmitBehavior: true
                                            }}/>
                                <ButtonItem horizontalAlignment="right"
                                            buttonOptions={{
                                                text: t("global.cancel"),
                                                type: 'cancel',
                                                onClick: () => navigate("/sensors")
                                            }}/>
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            </div>
        )
    } else {
        return(<ErrorPage/>);
    }
}

export default DeviceTypeRegisterForm;